// body{
//     background-color: #E5E5E5;
//     margin: 0px;
//     padding: 0px;
// }
.navheading[id=privacy]{
    font-size: 40px;
    font-weight: 700;
    margin-left: 285px;
}
.navheading2[id=privacy]{
    font-size: 28px;
    font-weight: 700;
    margin-left: 285px;
}
.navstyle[id=privacy]{
    // background: linear-gradient(357.7deg, #DDE7F1 13.13%, #FFFFFF 45.32%);
    padding-bottom: 102px;
    height: 615px;
}
.navpara[id=privacy]{
    font-size: 28px;
    font-weight: 600;
    margin-top: 4px;
}
.navpara1[id=privacy]{
    font-size: 16px;
    font-weight: 600;
    margin-top: 27px;
    padding-right: 872px;
    margin-left: 285px;
}
.privacymain[id=privacy]{
    font-weight: 600;
    font-size: 18px;
    margin-left: 100px;
    margin-right: 133px;
    margin-top: 47px;
    margin-bottom: 47px;
}
.privacyheading[id=privacy]{
    font-size: 28px;
    font-weight: 600;
}
.privacyhead[id=privacy]{
    font-size: 28px;
    font-weight: 600;
    margin-left: 100px;
    margin-right: 0px;
    margin-top: 47px;
   
}
.privacypara[id=privacy]{
    margin-top: 47px;  
}
.privacypara3[id=privacy]{
    font-weight: 400;
}
.privacypara2[id=privacy]{
    font-size: 18px;
    font-weight: 600;
    margin-left: 100px;
    margin-right: 105px;
    margin-top: 47px;
    margin-bottom: 47px;   
}
.privacypara1[id=privacy]{
    font-size: 18px;
    font-weight: 600;
    margin-left: 100px;
    margin-right: 105px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.privacycookie[id=privacy]{
    background-color: #F2F3F4;
}
.privacycookietext[id=privacy]{
    margin-left: 10px;
    color: #222222;
    font-size: 28px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
}

.grid-padding {
    padding: 0px 100px;
}

@media only screen and (max-width:600px) {
    .navstyle[id=privacy] {
        padding-left: 20px;
        padding-bottom: 20px;
    }

    .privacyheading[id=privacy] {
        font-size: 18px;
        font-weight: 700;
    }

    .privacypara[id=privacy] {
        margin-top: 0px;
        font-size: 18px;
        font-weight: 400;
    }

    .privacypara2[id=privacy] {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 400;
    }

    .privacyhead[id=privacy] {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 18px;
    }

    .privacymain[id=privacy] {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 400;
    }

    .privacycookietext[id=privacy] {
        padding-left: 0px;
        font-size: 18px;
        margin-left: 10px;
        font-weight: 700;
    }

    .navheading[id=privacy]{
        font-weight: 700;
        margin-left: 0px;
        font-size: 30px;
    }
    .navheading2[id=privacy]{
        font-size: 24px;
        font-weight: 700;
        margin-left: 0px;
    }
    .navpara1[id=privacy]{
        margin-top: 27px;
        padding-right: 0px;
        margin-left: 0px;
    }

    .grid-padding {
        padding: 0px 10px;
    }
  }

