.margin-top-40-custom {
  margin-top: 40px;

  @media only screen and (max-width: 960px) {
    margin-top: 0;
  }
}

.references-page-side-margin {
  padding: 0 100px !important;

  @media only screen and (max-width: 960px) {
    padding: 0 25px !important;
  }
}

.references-page-top-side-margin {
  padding: 5px 110px !important;

  @media only screen and (max-width: 960px) {
    padding: 5px 35px !important;
  }
}

.image-row-5 {
  width: 100%;
  max-height: 40%;
  height: 40%;

  @media only screen and (max-width: 960px) {
    max-height: 50%;
    height: 100%;
  }
}

.image-row-5-last {
  width: 100%;
  height: 81%;

  @media only screen and (max-width: 960px) {
    height: 100%;
  }
}

.ref-details-back {
  top: 50px;
  left: 100px;

  @media only screen and (max-width: 960px) {
    top: 15px;
    left: 20px;
  }
}

.ref-detail-top {
  width: 100%;
  margin-bottom: 200px;

  @media only screen and (max-width: 960px) {
    margin-bottom: 50px;
    background-size: cover !important;
  }
}

.image-1-resp {
  float: right;
  padding-left: 20px;
  padding-bottom: 20px;

  @media only screen and (max-width: 960px) {
    padding-left: 0;
    padding-bottom: 0;
    height: fit-content;
  }
}

.image-2-resp {
  float: right;
  padding-left: 10px;
  padding-bottom: 20px;

  @media only screen and (max-width: 960px) {
    padding-left: 0;
    padding-bottom: 0;
    height: fit-content;
  }
}

.image-3-resp {
  float: right;
  padding-left: 20px;
  padding-bottom: 20px;

  @media only screen and (max-width: 960px) {
    padding-left: 0;
    height: fit-content;
  }
}

.image-4-resp {
  float: right;
  padding-left: 5px;
  padding-bottom: 20px;

  @media only screen and (max-width: 960px) {
    padding-left: 0;
    height: fit-content;
  }
}

.image-5-resp {
  float: right;
  padding-left: 10px;
  padding-bottom: 10px;

  @media only screen and (max-width: 960px) {
    padding-left: 0;
    height: fit-content;
  }
}

.ref-page-main-margin-right {
  margin-right: 15px;

  @media only screen and (max-width: 960px) {
    margin-right: 0;
  }
}


.carousel-container {
  & div:nth-child(1) {
    & div:nth-child(1) {
      & ul {
        pointer-events: none;
      }
    }
  }
}