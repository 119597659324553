.main[id=contact]{
  margin-top: 40px;
  padding-left: 100px;
}
.form1[id=contact]{
    background-color: white;
    border-radius: 20px;
}

.sub[id=contact]{
    background-color: RGB(69, 84, 94);
    border-radius: 20px;
    overflow: hidden;
}
.para[id=contact]{
    
    color: white;
    font-size: 24px;
    font-weight: 600;
}
.paranew[id=contact]{
    font-size: 14px;
    font-weight: 600;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 40px;
    padding-right: 40px;
    
}
.logo[id=contact]{
    height: 20px;
    width: 20px;
}

.textform[id=contact]{
    margin-bottom: 40px;
}
.textform2[id=contact]{
    margin-bottom: 40px;    
}
.textform1[id=contact]{
    height: 56.37px;
    width: 607px;
    border-bottom: 1px solid rgb(128, 128, 128);
}
button[id=contact]{
    background-color: RGB(46, 160, 180);
    height: 48px;
    width: 191px;
    border-radius: 5px;
    margin-top:35px;
    color:white;
}
.button-alt[id=contact]{
    background-color: RGB(46, 160, 180);
    height: 40px;
    width: 180px;
    border-radius: 5px;
    margin-top: 10px;
    color:white;
    cursor: pointer;
}
.line[id=contact]{
    height:5px;
    width: 74px;
    background-color:RGB(46, 160, 180);
    padding-left: 30px;
}
.text[id=contact]{
    font-weight: 600;
    font-size: 14px;
    color:RGB(69, 84, 94);
    
}
.iconn[id=contact]{
    height: 16.02px;
    width: 25px;
}
.paratext[id=contact]{
    height: 19px;
    width: 222px;
    font-size: 14px;
    font-weight: 600;
}
.iconnnew[id=contact]{
    height: 20px;
    width: 20px;
}
.paratext1[id=contact]{
    color: RGBA(34, 34, 34, 0.5);
    font-size: 14px;
    font-weight: 600;
}
.formm{
    margin-top: 60px;
    border-radius:20px;
    margin-left: 40px; 
    margin-bottom: 60px;
}
.para1[id=contact]{
    height: 38px;
    width: 255px;
    font-size: 14px;
    font-weight: 600;
}

.bubble[id=contact] {
    height: 215px;
    width: 215px;
    border-radius: 125px;
    background-color: RGBA(255, 255, 255, 0.1);
    position: absolute;
    left: -75px;
    top: -75px;
}

.contact-circle {
    height: 185px;
    width: 185px;
    background-color: RGB(69, 84, 94);
    border-radius: 100px;

    &-container {
        height: 215px;
        width: 215px;
        background-color: RGBA(255, 255, 255, 0.1);
        border-radius: 125px;
        position: absolute;
        bottom: -100px;
        left: -100px;
    }
}

.contact-oval {
    height: 15px;
    width: 15px;
    margin-right: 25px;
    margin-bottom: 25px;
    border-radius: 15px;
    background-color: RGBA(255, 255, 255, 0.1);

    &-container {
        position: absolute;
        bottom: -10px;
        right: 0px;
    }
}

.contact-radio-button {
    &-container {
        height: 20px;
        width: 20px;
        // border: solid 1px RGB(170, 170, 170);
        background: white;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
    }

    &-blue {
        height: 100%;
        width: 100%;
        background-color: RGB(46, 160, 180);
    }

    &-white {
        height: 8px;
        width: 8px;
        background-color: white;
        border-radius: 8px;
    }
}

.contact {
    &-text {
        &-border {
            height: 100%;
                width: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                border-bottom: solid 1px #2EA0B4;

            &-default {
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                border-bottom: solid 1px #979797;
            }
        }
    }

    &-switch {
        height: 18px;
        width: 18px;
        border-radius: 20px;
        background-color: white;
        position: absolute;
        left: 1px;

        &-container {
            height: 20px;
            width: 35px;
            border-radius: 10px;
            background-color: #2EA0B4;
            position: relative;
        }
    }
}

.modal-box[id=contact] {
    
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
}

@media only screen and (max-width:600px) {
    .sub[id=contact] {
        margin-left: 0px;
    }

    .textform2[id=contact] {
        margin-left: 0px;
    }

    .main[id=contact] {
        margin-top: 0px;
        margin-left: 0px;
        box-sizing: border-box;
        padding: 0px 10px;
    }

    .formm[id=contact] {
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .form1[id=contact] {
        margin-top: 30px;
    }

    .contacteer{
        margin-bottom: 180px;
    }

    .contact-oval{
        margin-right: 15px;
    }

    .paranew[id=contact]{
        padding-right: 40px; 
    }

    .button[id=contact]{
        width: 100%;
    }

    .para1[id=contact]{
        padding-left: 15px; 
    }
  }
 
  