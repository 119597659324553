@import "../assets/styles/scss/design";

// partial imports here
@import "../components/space/space";
@import "../components/checkbox/checkbox";
@import "../components/error-message/error-message";
@import "../components/switch/switch";
@import '~rc-tooltip/assets/bootstrap';
@import "./views/home/_home";
@import "./views/home/components/_homepage_one";
@import "./views/home/components/_homepage_two";
@import "./views/home/components/_homepage_three";
@import "./views/header/_header";
@import "./views/footer/_footer";
@import "references/references";

body {
  overflow: auto;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: #fff;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: #fff;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #fff;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: #fff;
}

.rc-tooltip-inner {
  background-color: #fff;
  color: #46535c;
  font-size: 15px;
  padding: 20px 30px;
}

.font-color {
  &-black {
    color: black;
  }
  &-darkBleu{
    color:var(--darkBleu);
  }

  &-white {
    color: white;
  }

  &-grey {
    color: #9b9b9b;
  }

  &-dark-grey {
    color: #4a4a4a;
  }

  &-blue-grey {
    color: #45545D;
  }

  &-blue {
    color: #2DA0B3;
  }
  &-deep-blue {
    color: #2EA0B4;
  }
  &-light-black {
    color: rgb(34, 34, 34)
  }
}

.background-color {
  &-white {
    background: #ffffff;
  }

  &-faded-faint-grey {
    background: #F4F6F9;
  }

  &-light-blue-grey {
    background: #808a90;
  }

  &-dark-grey {
    background: #46535c;
  }
}

.container-full {
  height: 100vh;
  width: 100vw;
}

.link-underline-darkBlue{

  color:var(--darkBleu);

  text-decoration: underline ;
}

/* angled div */
.slanted-div {
  position: relative;
  padding: 100px 0 50px;
  background: linear-gradient(#ffffff 60%, #9dacc7 117%);
  overflow: visible;
  z-index: 1;
}

/* where the magic happens */
.slanted-div:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: right top;
  transform: skewY(-3deg);
}

.slanted-div:after {
  bottom: 0;
  transform-origin: right bottom;
  transform: skewY(3deg);
}

.slanted-div2 {
  position: relative;
  background: linear-gradient(#ffffff 60%, #9dacc7 117%);
  padding: 100px 0 0;
  overflow: visible;
  z-index: 1;
}

/* where the magic happens */
.slanted-div2::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: right top;
  transform: skewY(-3deg);
  border-radius: 25px;
}

.slanted-div2::after {
  bottom: 0;
  transform-origin: right bottom;
  transform: skewY(3deg);
}

