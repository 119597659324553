// body{
//     background-color: #E5E5E5;
// }
.para[id=disclaimer]{
    color: #222222;
    font-weight: 600;
    font-size: 18px;
    margin-left: 100px;
    margin-top: 47px;
    margin-right: 133px;  
}
.navheading[id=disclaimer]{
    font-size: 40px;
    font-weight: 700;
    margin-left: 220px;
}
.navstyle[id=disclaimer]{
    // background: linear-gradient(357.7deg, #DDE7F1 13.13%, #FFFFFF 45.32%);;   
    // padding-left: 249px;
    height: 615px;
}
.navpara[id=disclaimer]{
    font-size: 28px;
    font-weight: 600;
    margin-top: 4px; 
    margin-left: 220px;
}
.navpara1[id=disclaimer]{
    font-size: 16px;
    font-weight: 600;
    margin-top: 27px;
    margin-bottom: 195px;
    margin-right: 361px;
    margin-left: 220px;
}
.newpara[id=disclaimer]{
    font-size: 18px;
    font-weight: 600;
    color:#222222;   
}
.para1[id=disclaimer]{
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-left: 104px;
    margin-top: 51px;
}
.para2[id=disclaimer]{
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-left:53px;
    margin-top: 51px;
}
.heading[id=disclaimer]{
    font-size: 28px;
    font-weight: 600;
    color: black;
    margin-left: 100px;
    margin-top:78px;
}

.button[id=disclaimer] {
    height: 64px;
    width: 64px;
    box-shadow: 0px 1px 3px rgba(172, 172, 172, 0.5);
    border-radius: 15px;   
    background-color: white;
    cursor: pointer;
    margin-left: 100px;
}

.button-icon[id=disclaimer] {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

@media only screen and (max-width:600px) {
    .navstyle[id=disclaimer] {
        padding-left: 20px;
    }

    .button[id=disclaimer] {
        margin-left: 0px;
    }

    .navheading[id=disclaimer]{
        font-size: 40px;
        font-weight: 700;
        margin-left: 0px;
    }

    .navpara[id=disclaimer]{
        font-size: 28px;
        font-weight: 600;
        margin-top: 4px; 
        margin-left: 0px;
    }

    .navpara1[id=disclaimer]{
        font-size: 16px;
        font-weight: 600;
        margin-top: 27px;
        margin-bottom: 195px;
        margin-right: 361px;
        margin-left: 0px;
    }
  }
  @media only screen and (max-width:600px) {
    .newstyle[id=disclaimer] {
        padding-left: 20px;
    }
  }
  @media only screen and (max-width:600px) {
    .heading[id=disclaimer] {
        margin-left: 0px;
        margin-top: 20px;
    }
  }
  @media only screen and (max-width:600px) {
    .para[id=disclaimer] {
        margin-left: 0px;
        margin-top: 10px;
        margin-right: 0px;
    }
  }
  @media only screen and (max-width:600px) {
    .navpara1[id=disclaimer] {
        margin-left: 0px;
        margin-bottom: 20px;
        margin-right: 0px;
    }
  }