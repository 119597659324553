.container[id=footer] {
    height: 329px;
    width: 100%;
    background-color: RGB(242, 243, 244);
    box-sizing: border-box;
    padding: 0px 100px;
}

.logo[id=footer] {
    width: 290px;
    object-fit: contain;
}

.text[id=footer] {
    color: rgb(34, 34, 34);
}

@media only screen and (max-width: 600px) {
    .logo[id=footer] {
        width: 101px;
        object-position: contain;
    }

    .container[id=footer] {
        height: auto;
        width: 100%;
        background-color: RGB(242, 243, 244);
        box-sizing: border-box;
        padding: 10px;
    }
}