.container[id=header] {
    width: 100%;
    height: 100px;
    border-bottom: solid 1px #cecece;
    box-sizing: border-box;
    padding: 0px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo[id=header] {
    width: 200px;
    object-fit: contain;
}

.button[id=header] {
    height: 48px;
    width: 148px;
    border: solid 2px #2EA0B4;
    border-radius: 5px;
    cursor: pointer;
}

.underline[id=header] {
    width: 30px;
    height: 3px;
    background-color: RGB(46, 160, 180);
    border-radius: 5px;
    transform: translateY(15px);
}

.menu[id=header] {
    height: 40px;
    width: 40px;
    object-fit: contain;
    cursor: pointer;
}

.header-menu {
    height: 89px;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #45545D;

    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #45545D;
        transform-origin: top;
    }

    &-item {
        height: 52px;
        width: 100%;
    }
}

@media only screen and (max-width: 1023px) {
    .container[id=header] {
        height: auto;
        border-bottom: none;
        box-sizing: border-box;
        padding: 0px;
        background-color: transparent;
        flex-direction: column;
        border: none;
    }

    .logo[id=header] {
        transform: translateY(10px);
    }
}