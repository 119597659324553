.bar[id=homepagethree] {
    width: 820px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bar-container[id=homepagethree] {
    width: 100%;
    height: 125px;
    background-color: #F2F3F4;
    display: flex;
    justify-content: center;
}

.container[id=homepagethree] {
    width: 820px;
    display: flex;
    justify-content: space-between;
}

.card-container[id=homepagethree] {
    height: 436px;
    width: 397px;
    border-radius: 10px;
    overflow: hidden;
    padding: 30px;
    box-sizing: border-box;
}

.card-image-layer[id=homepagethree] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    object-fit: cover;
}

.card-layer[id=homepagethree] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.0001) 100%);
}

.card-bar[id=homepagethree] {
    width: 155px;
    height: 5px;
    background-color: #2EA0B4;
}

.button[id=homepagethree] {
    height: 64px;
    width: 64px;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(172, 172, 172, 0.5);
    border-radius: 15px;
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    right: 25px;
}

.button-icon[id=homepagethree] {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

.button-long[id=homepagethree] {
    height: 48px;
    width: 397px;
    max-width: 100%;
    border-radius: 10px;
    background-color: #45545E;
    cursor: pointer;
    position: relative;
}

.button-long-icon[id=homepagethree] {
    height: 20px;
    width: 20px;
    object-fit: contain;
    position: absolute;
    right: 15px;
}

.card-column[id=homepagethree] {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}

@media only screen and (max-width: 768px) {
    .container[id=homepagethree] {
        
    }

    .card-container[id=homepagethree] {
        height: 436px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        padding: 30px;
        box-sizing: border-box;
    }

    .card-column[id=homepagethree] {
        width: 50%;
        margin-right: 5px;
        margin-left: 5px;
    }
}

@media only screen and (max-width: 600px) {
    .container[id=homepagethree] {
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
    }

    .card-container[id=homepagethree] {
        height: 436px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        padding: 30px;
        box-sizing: border-box;
    }

    .card-column[id=homepagethree] {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }
}