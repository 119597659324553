.container[id=homepagetwo] {
    height: 600px;
    width: 100%;    
    box-sizing:border-box;
    padding: 0px 100px;
    position: relative;
    display: flex;
}

.background[id=homepagetwo] {
    height: 100%;
    width: calc(100% - 300px); // 200px is for 100px margin for left and right side
    background-color: RGB(242, 243, 244);
    position: absolute;
    top: 0px;
    left: 100px;
}

.background {
    &-circle {
        &-container-right {
            height: 200px;
            width: 200px;
            position: absolute;
            right: -100px;
            top: -100px;
            border: solid 15px white;
            border-radius: 100px;
        }   

        &-container-left {
            height: 200px;
            width: 200px;
            position: absolute;
            left: -100px;
            bottom: -100px;
            border: solid 15px white;
            border-radius: 100px;
        }   
    }
}

.content-box[id=homepagetwo] {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 50px 100px;
    padding-right: 0px;
}

.image-box[id=homepagetwo] {
    height: 496px;
    width: 496px;
    background-color: RGB(46, 160, 180);
    border-radius: 10px;
}

.image-box-content[id=homepagetwo] {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: translate(-10px, -10px);
    border-radius: 10px;
}

.dot[id=homepagetwo] {
    height: 15px;
    width: 15px;
    border-radius: 8px;
    background-color: #D8D8D8;
    margin-right: 23px;
    margin-bottom: 23px;
}

.dot-alt[id=homepagetwo] {
    height: 15px;
    width: 15px;
    border-radius: 8px;
    background-color: white;
    margin-right: 23px;
    margin-bottom: 23px;
}

.card[id=homepagetwo] {
    width: 496px;
    height: 268px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -100px;
    top: calc(100% - 75px);
    overflow: hidden;
}

.card-image-layer[id=homepagetwo] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    object-fit: cover;
}

.card-layer[id=homepagetwo] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #45545E;
    opacity: 0.8;
}

@media only screen and (max-width: 1024px) {
    .container[id=homepagetwo] {
        height: auto;
        padding: 50px;
    }

    .content-box[id=homepagetwo] {
        padding: 0px;
    }

    .background[id=homepagetwo] {
        width:calc(100% - 100px); 
        left: 25px;
    }
}

@media only screen and (max-width: 768px) {
    .container[id=homepagetwo] {
        height: auto;
        padding: 0px;
    }

    .content-box[id=homepagetwo] {
        padding: 15px;
    }

    .background[id=homepagetwo] {
        width: 100%; 
        left: 0px;
    }

    .dot[id=homepagetwo] {
        height: 8px;
        width: 8px;
        border-radius: 5px;
        margin-right: 12px;
        margin-bottom: 12px;
    }

    .dot-alt[id=homepagetwo] {
        height: 8px;
        width: 8px;
        border-radius: 5px;
        margin-right: 12px;
        margin-bottom: 12px;
    }

    .background {
        &-circle {
            &-container-right {
                height: 100px;
                width: 100px;
                right: -60px;
                top: -40px;
                border: solid 7px white;
                border-radius: 100px;
            }   
    
            &-container-left {
                height: 100px;
                width: 100px;
                left: -60px;
                bottom: -60px;
                border: solid 7px white;
                border-radius: 100px;
            }   
        }
    }

    .image-box[id=homepagetwo] {
        height: 400px;
        width: 100%;
        background-color: RGB(46, 160, 180);
        border-radius: 10px;
        transform: translateY(0px);
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .card[id=homepagetwo] {
        position: relative;
        left: -175px;
        top: -100px;
        height: 175px;
    }
}

@media only screen and (max-width: 600px) {
    .container[id=homepagetwo] {
        height: auto;
        padding: 0px;
    }

    .content-box[id=homepagetwo] {
        padding: 15px;
    }

    .background[id=homepagetwo] {
        width: 100%; 
        left: 0px;
    }

    .dot[id=homepagetwo] {
        height: 8px;
        width: 8px;
        border-radius: 5px;
        margin-right: 12px;
        margin-bottom: 12px;
    }

    .dot-alt[id=homepagetwo] {
        height: 8px;
        width: 8px;
        border-radius: 5px;
        margin-right: 12px;
        margin-bottom: 12px;
    }

    .background {
        &-circle {
            &-container-right {
                height: 100px;
                width: 100px;
                right: -60px;
                top: -40px;
                border: solid 7px white;
                border-radius: 100px;
            }   
    
            &-container-left {
                height: 100px;
                width: 100px;
                left: -60px;
                bottom: -60px;
                border: solid 7px white;
                border-radius: 100px;
            }   
        }
    }

    .image-box[id=homepagetwo] {
        height: 228px;
        width: 265px;
        background-color: RGB(46, 160, 180);
        border-radius: 10px;
        transform: translateY(-25px);
    }

    .card[id=homepagetwo] {
        position: relative;
        left: 0px;
        top: 0px;
        height: 175px;
    }
}