.container[id=homepageone] {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 100px;
}

.card[id=homepageone] {
    height: 69px;
    width: 185px;
    border-radius: 10px;
    background-color: RGB(241, 243, 243);
    box-sizing: border-box;
    padding: 13px 13px;
    cursor: pointer;
}

.background-container[id=homepageone] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
}

.background[id=homepageone] {
    background: linear-gradient(357.7deg, rgba(145, 171, 197, 0.103174) 13.13%, rgba(145, 171, 197, 0.0001) 45.32%);
    height: 100%;
    width: 100%;
    transform-origin: top left;
    transform: skewY(-3deg);
}

.background-alt[id=homepageone] {
    background: linear-gradient(357.7deg, #DDE7F1 13.13%, #FFFFFF 45.32%);
    height: 100%;
    width: 100%;
    transform-origin: top left;
    transform: skewY(-3deg);
}

.image[id=homepageone] {
    width: 713px;
    height: 429px;
    transform-origin: top left;
    transform: skewY(-3deg);
    overflow: hidden;
    border-bottom-right-radius: 80px;
}

.image-main[id=homepageone] {
    transform-origin: top left;
    transform: skewY(3deg);
    border-radius: 20px 20px 0px 20px;
    object-fit: cover;
}

.bar[id=homepageone] {
    height: 5px;
    width: 185px;
    background-color: #2EA0B4;
}

.arrow-left[id=homepageone] {
    height: 64px;
    width: 64px;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(172, 172, 172, 0.5);
    border-radius: 15px;
    position: absolute;
    top: calc(50% - 32px);
    left: -32px;
    cursor: pointer;
}

.arrow-right[id=homepageone] {
    height: 64px;
    width: 64px;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(172, 172, 172, 0.5);
    border-radius: 15px;
    position: absolute;
    top: calc(50% - 32px);
    right: -32px;
    cursor: pointer;
}

.arrow-icon[id=homepageone]  {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

.button[id=homepageone] {
    height: 48px;
    width: 396px;
    border-radius: 5px;
    background-color: #45545E;
}

.button-long-icon[id=homepageone] {
    height: 20px;
    width: 20px;
    object-fit: contain;
    position: absolute;
    right: 15px;
}

.background-text-container[id=homepageone] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    bottom: 25px;
    transform-origin: top left;
    transform: skewY(3deg);
}

.slider-bubble[id=homepageone] {
    height: 12px;
    width: 12px;
    border-radius: 10px;
    background-color: white;
    border: solid 1px #4a4a4a;
    margin-right: 5px;
    box-sizing: border-box;
}

.parsed-html {
    background-color: transparent;
    
    p {
        background-color: transparent !important;
        font-family: 'Vietnam' !important;
    }

    span {
        background-color: transparent !important;
        display: inline;
        font-family: 'Vietnam' !important;
    }
}

@media only screen and (max-width: 1024px) {
    .container[id=homepageone] {
        padding: 0px 50px;
    }

    .card[id=homepageone] {
        height: 69px;
        width: 95%;
        border-radius: 10px;
        background-color: RGB(241, 243, 243);
        box-sizing: border-box;
        padding: 13px 13px;
        cursor: pointer;
    }
    

    .homepage-mobile-padding  {
        box-sizing: border-box;
        padding: 0px 8px;
    }
}

@media only screen and (max-width: 768px) {
    .container[id=homepageone] {
        padding: 0px;
    }

    .card[id=homepageone] {
        height: 69px;
        width: 95%;
        border-radius: 10px;
        background-color: RGB(241, 243, 243);
        box-sizing: border-box;
        padding: 13px 13px;
        cursor: pointer;
    }
    

    .homepage-mobile-padding  {
        box-sizing: border-box;
        padding: 0px 8px;
    }

    .button[id=homepageone] {
        margin-top: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .container[id=homepageone] {
        padding: 0px;
    }

    .card[id=homepageone] {
        height: 69px;
        width: 95%;
        border-radius: 10px;
        background-color: RGB(241, 243, 243);
        box-sizing: border-box;
        padding: 13px 13px;
        cursor: pointer;
    }
    

    .homepage-mobile-padding  {
        box-sizing: border-box;
        padding: 0px 8px;
    }
}